// permet d'appeler des fonctions directement dans les componsants sans faire d'import de fichier.
// ces composants sont appelés this this.xxxx
// exemple : this.$api.get ...

import store from "../store/store";
import moment from "moment";
import router from "./customRouter.js";
import main from "../components/main/mainComponent";
import localization from 'moment/locale/fr';


import textfield from '../components/fields/textfield'
import select from '../components/fields/select'
import radio from '../components/fields/radio'
import SwitchC from '../components/fields/switch'
import colorpicker from '../components/fields/colorPicker'
import arraytable from '../components/fields/arrayTable'
import autocomplete from '../components/fields/autocomplete'
import datepicker from '../components/fields/datePicker'
import datetimepicker from '../components/fields/dateTimePicker'
import textarea from '../components/fields/textarea'
import fileinput from '../components/fields/fileInput'
import texteditor from '../components/fields/texteditor'
import encartphoto from '../components/fields/encartPhoto'

import { EventBus } from './EventBus.js';



const functions = {
    install(Vue) {
        Vue.prototype.randomKey = (length) => {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }

        Vue.prototype.moment = (date) => {
            moment.locale('fr', localization)
            return moment(date)
        }

        Vue.prototype.getAllPages = () => {
            return new Promise((resolve) => {
                Vue.prototype.API({ type: 'get', url: 'allpage' }).then((resp) => {
                    resp.data.forEach((element) => {
                        let rout = {
                            path: element.url,
                            component: main,
                            meta: {},
                            name: element.name,
                            children: [],
                            engine: element.engine,
                            table: element.table,
                            parentform: element.parentform,
                            params: element.displays.params,
                            displayname: element.displays.displayname,
                            // obligatoire pour l'envoi dans le component
                            props: {
                                page: element.id,
                                type: element.type,
                            },
                            //props: (route) => ({ page: element.id, type: element.type, get: route.query }),
                        };

                        if (element.children && element.children.length > 0) {

                            element.children.forEach((child) => {
                                let routchild = {
                                    path: child.url,
                                    component: main,
                                    meta: { equiresAuth: true },
                                    name: child.name,
                                    engine: child.engine,
                                    table: child.table,
                                    parentform: child.parentform,
                                    params: child.displays.params,
                                    displayname: child.displays.displayname,
                                    props: {
                                        page: child.id,
                                        type: child.type,

                                    },
                                };
                                rout.children.push(routchild);
                                //router.addRoute(routchild);
                                delete routchild.component;
                                store.dispatch("pushAll", routchild);
                            })
                        }

                        router.addRoute(rout);



                        delete rout.component;
                        store.dispatch("pushAll", rout);
                        resolve(true);
                    });
                });
            });
        };

        // permet de spliter l'url de base(client/:id) et de le remplacer par l'id que l'on a placé dans dans l'élément replace de la fonction gotTo
        Vue.prototype.newurl = (elem) => {
            let targetPage = store.getters["listAll"].find(
                (el) => el.name === elem.page
            );

            if (!targetPage) {
                targetPage = store.getters["listAll"].find(
                    (el) => el.path === elem.page
                );
            }

            var newurl = targetPage.path;
            var res = newurl.split("/");
            var validUrl = true;
            res.forEach(function(v, index) {
                for (var rep in elem.replace) {
                    if (v == ":" + rep) {
                        res[index] = elem.replace[rep];
                        if (!elem.replace[rep] || elem.replace[rep] == 'undefined') validUrl = false
                    }
                }
            });
            return (validUrl) ? res.join("/") : false;
        };


        // raccourcie pour appeler une page ( soit en modal, soit en page centrale)
        // variables pour elements :
        // page : id de la page qui va etre appelé - (numerique)
        // replace : arguments de l'url, exemple : {id: [id_du_client]} - (array)
        // type : modal ou center - (string)
        // rootPage : page ou se trouve la fonction goTo - (numerique)
        // paramDialog : paramètres du modal, voir dans templates - modalTemplate avec la var paramDialog - (array)
        Vue.prototype.goTo = (elements) => {

            let targetPage = store.getters["listAll"].find(
                (el) => el.name === elements.page
            );

            var newurl = Vue.prototype.newurl({
                page: elements.page,
                replace: elements.replace,
            });
            // on check si les params sont bien définis, sinon on arrete l'envoi de le page
            if (!newurl) {
                Vue.$toast.error('Paramètres manquants, vous ne pouvez pas accéder à cette page', { position: "top-right", timeout: 3000 });
                return false;
            }

            //si on veut juste appliquer une query sans faire appel a la page.
            if (elements.query && elements.reload) {
                if (!elements.modal) {
                    router.replace({ query: elements.query });
                } else {
                    store.dispatch('changeOptionsModal', { modal: { type: 'to', val: elements.page }, type: 'query', value: elements.query });
                }
                return
            }


            var replace = elements.replace;
            var varsUrl = targetPage.params.varurl;
            if (Array.isArray(varsUrl)) {
                var construct = {}
                varsUrl.forEach(function(v) {
                    construct[v['name']] = Vue.prototype.replaceBy({ text: v['value'], page: targetPage.props.page });
                })
                replace = {...construct, ...replace };
            }
            if (elements.type == "modal") {

                store.dispatch("showModal", {
                    newurl,
                    propsPage: targetPage,
                    from: elements.rootPage,
                    params: replace,
                    query: elements.query,
                    paramDialog: elements.paramDialog,
                    fromModule: elements.fromModule,
                });
            } else if (elements.type == "center") {

                router.push({ name: elements.page, params: replace, query: elements.query });
            } else {
                alert(
                    "mauvais parametrage de direction : aucun type d'ouverture selectionné"
                );
            }
        };


        // envoie vers l'api en intégrant le queue
        Vue.prototype.API = (elements) => {
            return new Promise((resolve) => {
                var params = (!elements.params) ? {} : elements.params;
                store.dispatch("callAxios", { token: Vue.prototype.randomKey(6), type: elements.type, url: elements.url, params: params, apidefault: elements.apidefault }).then((resp) => {
                    resolve(resp)
                });

            })
        }

        Vue.prototype.checkVentilation = (elements) => {

            return new Promise((resolve, reject) => {
                let getResponseKey = (!elements.getResponseKey) ? {} : elements.getResponseKey;
                let el = elements.ventilation[elements.row];
                //on récupère les lignes de before et after, ainsi que les potentielles insertions et requetes simple pour lesrelancer ds la backe et les utiliser
                getResponseKey['lines'] = el.result.lines;
                var wait = false;
                var stop = false;
                //chaque returnfront représente une action a envoyé dans le front pour une ventilation
                if (el && el.result) el.result.returnfront.forEach((returnFront) => {
                    if (!wait) {
                        switch (returnFront.type) {
                            case 'ifconfirm':

                                //le setTimeout pour juste laisser le temps à des refresh de module ou autre (en meme temps que le ifconfirm se lance)

                                if (confirm(returnFront.text)) {
                                    getResponseKey[returnFront.key] = false
                                    Vue.prototype.API({ type: 'post', url: 'displaymodule', params: { id: el.id, params: el.params, response: getResponseKey, page: el.page } }).then((resp) => {
                                        elements.ventilation[elements.row].result = resp.data.result
                                        resolve(Vue.prototype.checkVentilation({ ventilation: elements.ventilation, page: elements.page, getResponseKey: getResponseKey, row: elements.row }))

                                    })
                                } else {
                                    getResponseKey[returnFront.key] = true
                                    Vue.prototype.API({ type: 'post', url: 'displaymodule', params: { id: el.id, params: el.params, response: getResponseKey, page: el.page } }).then((resp) => {
                                        elements.ventilation[elements.row].result = resp.data.result
                                        resolve(Vue.prototype.checkVentilation({ ventilation: elements.ventilation, page: elements.page, getResponseKey: getResponseKey, row: elements.row }))

                                    })
                                }

                                wait = true
                                break;

                            case 'chooseuser':


                                getResponseKey[returnFront.key] = true
                                EventBus.$emit('sendDatas', { message: returnFront.text, actions: returnFront.actions, crochet: '', page: elements.page });

                                EventBus.$on('send-data', (data) => {
                                    EventBus.$off('send-data');
                                    getResponseKey['lines'][returnFront.choicekey] = data;
                                    Vue.prototype.API({ type: 'post', url: 'displaymodule', params: { id: el.id, params: el.params, response: getResponseKey, page: el.page } }).then((resp) => {
                                        elements.ventilation[elements.row].result = resp.data.result
                                        resolve(Vue.prototype.checkVentilation({ ventilation: elements.ventilation, page: elements.page, getResponseKey: getResponseKey, row: elements.row }))

                                    })
                                });
                                wait = true;
                                break;
                            case 'alert':

                                Vue.$toast[returnFront.state](returnFront.text);

                                break;

                                //permet de renvoyer le fait que la requete a bein été effectué, elle ne se lancera plus par la suite.

                            case 'stop':

                                reject();
                                stop = true;
                                break;

                            case 'overlay':
                                getResponseKey[returnFront.key] = true
                                store.dispatch('textOverlay', returnFront.text)
                                store.dispatch('getoverlay', returnFront.isopen);

                                Vue.prototype.API({ type: 'post', url: 'displaymodule', params: { id: el.id, params: el.params, response: getResponseKey, page: el.page } }).then((resp) => {
                                    elements.ventilation[elements.row].result = resp.data.result
                                    resolve(Vue.prototype.checkVentilation({ ventilation: elements.ventilation, page: elements.page, getResponseKey: getResponseKey, row: elements.row }))

                                })
                                wait = true;


                                break;

                            case 'reloadmodule':
                                store.dispatch('addReloadModule', { modules: returnFront.module })
                                break;

                            case 'loadmodule':
                                store.dispatch('addVentilation', { type: 'loadmodule', module: returnFront.module, crochet: returnFront.crochet, datas: returnFront.datas, page: elements.page })
                                break;

                            case 'changestore':
                                returnFront.fields.forEach((field) => {

                                    store.dispatch('pushForm', { page: elements.page, value: field.value, champ: field.field })
                                })

                                break;

                            default:
                                //permet de renvoyer le fait que la requete a bein été effectué, elle ne se lancera plus par la suite.
                                getResponseKey[returnFront.key] = returnFront.type;

                        }
                    }

                })

                if (stop) {
                    reject();
                    return;
                } else {
                    if (!wait) {

                        if (elements.ventilation[elements.row + 1]) {

                            resolve(Vue.prototype.checkVentilation({ ventilation: elements.ventilation, page: elements.page, getResponseKey: {}, row: elements.row + 1 }))
                        } else {

                            resolve();
                        }
                    }
                }

            });

        }





        Vue.prototype.validateForm = (elements) => {
            //action de validation du formulaire
            let modal = store.getters.modalShowParams({ type: "to", val: elements.page, });
            // on récupère l'url pour le lancement du put vers l'API (l'url du formulaire est similaire à l'url de modification du form)
            var urlSend = (!modal || modal.show == false) ? router.history.current.path : modal.url
                //si ca vient d'un formulaire indépendant :
            urlSend = (elements.urlSend) ? elements.urlSend : urlSend;
            var params = (!modal || modal.show == false) ? router.params : modal.params


            store.dispatch('getoverlay', true)
            if (elements.form.validate()) {
                let listForm = store.getters.listForm(elements.page)
                    // validation du formulaire  

                // on récupère le type d'enregistrement :
                let getTypeValidate = (!elements.validate && listForm) ? listForm.validate : elements.validate;

                // permet de cibler les champs qui sont updatés : soit les champs classiques via le store, 
                //soit les champs issues d'un form indépendant (via le FormModule)
                let valFields = (!elements.fields) ? listForm['fields'] : elements.fields;


                //première étape, envoi pour vérification des ventilations :
                //Lancement des ventilations pour vérification avant enregistrement. 


                //début d'enregistrement du formulaire
                Vue.prototype.API({ type: 'put', url: urlSend, params: { fields: valFields, ventilation: elements.ventilation } }).then((resp) => {

                        store.dispatch('getoverlay', false)

                        if (resp.data.success) {
                            //si l'enregistrement détecte des ventilations avant l'action, la function ventil prend la relève. dès que cette function est trerminé et retourne true, 
                            // on repart sur un nouvel enregistrement, en mettant une var dans l'url pour ne plus lancer les ventils. 
                            if (resp.data.ventilation && resp.data.ventilation.before) {
                                store.dispatch('getoverlay', true)
                                Vue.prototype.checkVentilation({ ventilation: resp.data.ventilation.before, page: elements.page, row: 0 }).then(() => {
                                    store.dispatch('getoverlay', false);
                                    elements.ventilation = true;

                                    return Vue.prototype.validateForm(elements)
                                }).catch(() => {
                                    store.dispatch('getoverlay', false);
                                    elements.ifNotValid()
                                    return false;
                                })
                                return;
                            }

                            if (resp.data.isStored) Vue.$toast.success('enregistré', { position: "bottom-right", timeout: 2000 });

                            //permet de stocker la dernière insertion ou le dernier update
                            store.dispatch('setLastStored', { page: elements.page, fields: resp.data.args });

                            //si l'enreg est en mode classique
                            if (!elements.fields) {
                                //si c'est un enregistrement frais
                                if (resp.data.new) {

                                    // si c'est une modal
                                    if (modal && modal.show == true) {

                                        switch (getTypeValidate) {

                                            case 1:
                                                //validation qui entraine directement vers le mode édition de cette ligne
                                                //1 - on change l'url de destination pour mettre la nouvelle                                   
                                                urlSend = Vue.prototype.newurl({ page: resp.data.redirect, replace: resp.data.args });

                                                store.dispatch('changeOptionsModal', { modal: { type: 'to', val: elements.page }, type: 'propsPage', key: 'name', value: resp.data.redirect });
                                                store.dispatch('changeOptionsModal', { modal: { type: 'to', val: elements.page }, type: 'propsPage', key: 'path', value: urlSend });
                                                store.dispatch('changeOptionsModal', { modal: { type: 'to', val: elements.page }, type: 'url', value: urlSend });
                                                store.dispatch('changeOptionsModal', { modal: { type: 'to', val: elements.page }, type: 'params', value: resp.data.args });
                                                store.dispatch('changeParamForm', { page: elements.page, type: 'typeform', value: 'edit' });
                                                break;

                                            case 2:
                                                //validation qui entraine un nouveau formulaire
                                                //appel de l'api pour récupérer les valeurs des champs
                                                // les params permettent de récupérer des POST à envoyer dans l'API afin de préremplir les champs l'ors de l'insert
                                                Vue.prototype.API({ type: 'get', url: urlSend, params: params }).then((resp) => {
                                                    // on réinitialise le store du formulaire
                                                    store.dispatch('initForm', { page: elements.page, valid: true });
                                                    for (const array in resp.data.valFields) {
                                                        store.dispatch('pushForm', { page: elements.page, champ: array, value: resp.data.valFields[array] });
                                                    }

                                                })
                                                break;

                                            case 3:
                                                //validation qui ferme de suite la modale apres
                                                store.dispatch('closeModal', { page: store.getters.modalShowParams({ type: 'to', val: elements.page }).from })
                                                break;
                                        }

                                    } else {
                                        //si enreg frais et pas modal
                                        switch (getTypeValidate) {

                                            case 1:
                                                //validation qui entraine directement vers le mode édition de cette ligne
                                                Vue.prototype.goTo({ rootPage: elements.page, page: resp.data.redirect, type: 'center', replace: resp.data.args })
                                                break;

                                            case 2:
                                                //validation qui entraine un nouveau formulaire
                                                router.go()
                                                break;

                                            case 3:
                                                //validation qui ferme de suite la modale apres
                                                //si ca ferme automatiquement, on reviens à le précédente page
                                                router.go(-1)
                                                break;
                                        }
                                        // si c'est une page centrale, et nouveau form on rafraichi tout simplement


                                    }

                                } else {
                                    //si le formulaire est déjà en mode édition'
                                    if (modal && modal.show == true) {
                                        //et que c'est une modal

                                        if (getTypeValidate == 3) {
                                            // si la validation entraine la fermeture automatique de la modal
                                            store.dispatch('closeModal', { page: store.getters.modalShowParams({ type: 'to', val: elements.page }).from })
                                        }
                                    } else {
                                        //si ce n'est pas un modal 
                                        if (getTypeValidate == 3) {
                                            //si ca ferme automatiquement, on reviens à le précédente page
                                            router.go(1)
                                        }
                                    }



                                }


                            } // fin enreg en mode classique (via le store). si on veut rajouter des actions après enreg en mode indépendant,
                            // mettre un else à  ce niveau la 

                            //lancement des ventilations (si il y a) après enregistrement 
                            if (resp.data.ventilation && resp.data.ventilation.after.length > 0) {
                                Vue.prototype.checkVentilation({ ventilation: resp.data.ventilation.after, page: elements.page, row: 0 }).catch(() => {})
                            }

                            elements.callback(resp.data);

                        } else {
                            Vue.$toast.error(resp.data.message, { position: "bottom-right", timeout: 2000 });
                            elements.ifNotValid()
                        }

                    })
                    //fin d'enregistrement du formulaire




            } else {
                store.dispatch('getoverlay', false)
                elements.ifNotValid()
            }

        }



        Vue.prototype.clickActions = (elements) => {
            // args : éléments de la ligne sélectionné provenants de la base, envoyé par le module.
            // action : array du l'action comportant les arguments de l'action
            //idmodule : id du module ou se trouve l'action
            //page : la page d'ou vient l'action
            //selectedLines : si c'est du module table, cela peut interagir avec plusieurs lignes. dans ce cas c'est true
            var params = {}
            var query = {}
            if (elements.action.typelink == 'link' || elements.action.typelink == 'api') {
                params['line'] = elements.args
                elements.action.get.forEach((v) => {

                    if (v.typedata == 'array') {
                        params[v.key] = {}
                        v.value.forEach((av) => {
                            params[v.key][av.key] = Vue.prototype.replaceBy({ text: av.value, page: elements.page });
                            if (av.type == 'table') params[v.key][av.key] = elements.args[av.value]
                        })
                    } else {
                        if (v.typevar == "query") {
                            query[v.key] = Vue.prototype.replaceBy({ text: v.value, page: elements.page });
                            if (v.type == 'table') query[v.key] = elements.args[v.value]
                        } else {
                            params[v.key] = Vue.prototype.replaceBy({ text: v.value, page: elements.page });
                            if (v.type == 'table') params[v.key] = elements.args[v.value]
                        }
                    }

                })

            }

            if (elements.action.typelink == 'link') {
                Vue.prototype.goTo({ rootPage: elements.page, page: elements.action.page_name, type: elements.action.target, replace: params, query: query, paramDialog: elements.action.paramdialog, fromModule: elements.idmodule })
                if (elements.whenlink) elements.whenlink()
            }

            if (elements.action.typelink == 'api') {
                let act = elements.action.params
                params['action'] = elements.action.id;
                params['selected'] = elements.selectedLines;
                if ((act.ifconfirm && act.ifconfirm != '' && confirm(act.ifconfirm)) || (!act.ifconfirm || act.ifconfirm == '')) {

                    Vue.prototype.API({ type: act.apilink.type, url: Vue.prototype.replaceBy({ text: act.apilink.url, page: elements.page, line: elements.args }), params: params }).then((resp) => {
                        if (resp.data.ventilation) {
                            // si il ya une ventil avant modif d'un champ
                            if (resp.data.ventilation.before) {
                                Vue.prototype.checkVentilation({ ventilation: resp.data.ventilation.before, page: elements.page, row: 0 }).then(() => {

                                    }).catch(() => {
                                        return;
                                    })
                                    // si il ya une ventil après modif d'un champ
                            } else if (resp.data.ventilation.after) {
                                Vue.prototype.checkVentilation({ ventilation: resp.data.ventilation.after, page: elements.page, row: 0 })
                            } else {
                                // si on appelle directement un module de ventilation
                                Vue.prototype.checkVentilation({ ventilation: [resp.data], page: elements.page, row: 0 }).catch(() => { return; })
                            }

                        }

                        if (resp.data.success) {
                            elements.success(resp.data)
                        } else {
                            if (resp.data.message) Vue.$toast.error(resp.data.message);
                        }
                    });

                }

            }

            if (elements.action.typelink == 'href') {
                if (Vue.prototype.replaceBy({ text: elements.action.params.apilink.url, page: elements.page, line: elements.args }) &&
                    Vue.prototype.replaceBy({ text: elements.action.params.apilink.url, page: elements.page, line: elements.args }) != 'null') {
                    window.open(Vue.prototype.replaceBy({ text: elements.action.params.apilink.url, page: elements.page, line: elements.args }), '_blank');
                } else {
                    Vue.$toast.error('Lien non inséré')
                }

            }

        }


        // permet de cibler quel composant va etre utiliser pour affichage : serv essentiellement dans pageTemplate et modalTemplate
        Vue.prototype.getTemplate = (elements) => {
            var component = "notfound-component";
            switch (elements.type) {
                case "page":
                    component = "page-template";
                    break;
                case "form":
                    component = "form-template";
                    break;

                default:
                    component = "notfound-component";
            }
            return component;
        };

        //permet de cibler quel modale va etre affiché : serv essentiellement dans templates/page/elements/module.vue
        Vue.prototype.getComponent = (elements) => {
            var component = "notfound-component";
            switch (elements.component) {
                case "table":
                    component = "table-component";
                    break;
                case "search":
                    component = "search-component";
                    break;
                case "form":
                    component = "form-component";
                    break;
                case "calendar":
                    component = "calendar-component";
                    break;
                case "tabs":
                    component = "tabs-component";
                    break;
                case "pdf":
                    component = "pdf-component";
                    break;
                case "bibliotheque":
                    component = "bibli-component";
                    break;
                case "sendemail":
                    component = "sendemail-component";
                    break;
                case "historiqueemail":
                    component = "historiqueemail-component";
                    break;
                case "frontdatas":
                    component = "frontdatas-component";
                    break;
                case "ged":
                    component = "ged-component";
                    break;
                case "ventilation":
                    component = "ventilation-component";
                    break;
                default:
                    component = "notfound-component";

            }
            return component;
        };

        //permet d'afficher dans les textes des éléments dynamiques : [param_target||nom_param]
        Vue.prototype.replaceBy = (elements) => {
            // le commentaire juste en dessous permet de supprimer une erreur js, donc à ne pas supprimer
            /* eslint-disable no-useless-escape */
            if (!elements.text) return false;
            var str = elements.text.match(/(?<=\[\[)([^\[\]]*)(?=\]\])/gim);
            var returnText = elements.text;
            if (Array.isArray(str))
                str.forEach((match) => {
                    if (match.indexOf("||") > 0) {
                        var form = store.getters.listForm(elements.page);
                        var page = store.getters.specificPage(elements.page);
                        var modal = store.getters.modalShowParams({
                            type: "to",
                            val: elements.page,
                        });

                        var def = match.split("||");

                        var deep = def[1].split(' ')
                        switch (def[0]) {
                            case "form":
                                if (form && form.fields) {
                                    var findInArray = form
                                    deep.forEach((el) => {
                                        findInArray = findInArray[el]
                                    })
                                    findInArray = (typeof findInArray === 'undefined') ? "" : findInArray;
                                    returnText = returnText.replace(
                                        "[[" + match + "]]",
                                        findInArray
                                    );

                                }
                                break;
                            case "user":

                                findInArray = store.getters.currentUser
                                deep.forEach((el) => {
                                    findInArray = findInArray[el]
                                })
                                findInArray = (typeof findInArray === 'undefined') ? "" : findInArray;
                                returnText = returnText.replace(
                                    "[[" + match + "]]",
                                    findInArray
                                );
                                break;
                            case "frontdatas":
                                findInArray = store.getters.getFrontDatas
                                deep.forEach((el) => {
                                    findInArray = (findInArray[el]) ? findInArray[el] : ""
                                })
                                findInArray = (typeof findInArray === 'undefined') ? "" : findInArray;
                                returnText = returnText.replace(
                                    "[[" + match + "]]",
                                    findInArray
                                );
                                break;

                            case "page":
                                if (page) {
                                    findInArray = page
                                    deep.forEach((el) => {
                                        findInArray = findInArray[el]
                                    })
                                    findInArray = (typeof findInArray === 'undefined') ? "" : findInArray;
                                    returnText = returnText.replace(
                                        "[[" + match + "]]",
                                        findInArray
                                    );
                                }
                                break;
                            case "routerparam":
                                if (modal && modal.show == true) {
                                    returnText = returnText.replace(
                                        "[[" + match + "]]",
                                        modal.params[def[1]]
                                    );
                                } else {
                                    returnText = returnText.replace(
                                        "[[" + match + "]]",
                                        router.history.current.params[def[1]]
                                    );

                                }
                                break;

                            case "routerquery":
                                if (modal && modal.show == true) {
                                    findInArray = (typeof modal.query[def[1]] === 'undefined') ? "" : modal.query[def[1]];
                                    returnText = returnText.replace(
                                        "[[" + match + "]]",
                                        findInArray
                                    );
                                } else {
                                    findInArray = (typeof router.history.current.query[def[1]] === 'undefined') ? "" : router.history.current.query[def[1]];
                                    returnText = returnText.replace(
                                        "[[" + match + "]]",
                                        findInArray
                                    );

                                }

                                break;

                            case "table":
                                if (elements.line) {
                                    findInArray = elements.line
                                    deep.forEach((el) => {
                                        findInArray = findInArray[el]
                                    })
                                    findInArray = (typeof findInArray === 'undefined') ? "" : findInArray;
                                    returnText = returnText.replace(
                                        "[[" + match + "]]",
                                        findInArray
                                    );
                                }
                                break;
                        }
                    }
                });

            return returnText;
        };

        Vue.prototype.createNewPage = (elements) => {
            Vue.prototype.API({ type: 'post', url: '/newpage', params: elements }).then((resp) => {
                resp.data.forEach((page) => {

                    let rout = {
                        path: page.url,
                        component: main,
                        name: page.name,
                        displayname: page.displays.displayname,
                        children: page.children,
                        engine: page.engine,
                        table: page.table,
                        parentform: page.parentform,
                        params: page.displays.params,
                        // obligatoire pour l'envoi dans le component
                        props: {
                            page: page.id,
                            type: page.type,

                        },
                    };

                    if (page.id) {
                        router.addRoute(rout);
                        delete rout.component;
                        store.dispatch("pushAll", rout);
                        //router.push({ name: resp.data.name });
                    }

                })
                elements.callback(resp.data);

            });
        };
        // permet de changer un store à partir du paramétrage otherparams du champ
        Vue.prototype.changeStoreOnField = (elements) => {

            if (!elements.item.otherparams || !Array.isArray(elements.item.otherparams.store) || elements.item.otherparams.store.length == 0) return false;

            elements.item.otherparams.store.forEach((storeParam) => {
                const keys = storeParam.listKeys.split(' ')

                switch (storeParam.type) {

                    case 'user':
                        store.dispatch('updateUser', { keys: keys, value: elements.model })

                        break;
                    case 'form':
                        if (keys.length == 2) {
                            store.dispatch('pushForm', { champ: keys[0], key: keys[1], value: elements.model, page: elements.page })
                        } else {
                            store.dispatch('pushForm', { champ: keys[0], value: elements.model, page: elements.page })
                        }
                        break;
                    case 'page':
                        if (keys.length == 2) {

                            store.dispatch('changeParamPage', { page: elements.page, item: keys[0], key: keys[1], value: elements.model })
                        } else {
                            store.dispatch('changeParamPage', { page: elements.page, item: keys[0], value: elements.model })
                        }

                        break;
                    case 'modal':

                        if (keys.length == 2) {
                            store.dispatch('changeOptionsModal', { modal: { type: 'to', val: elements.page }, type: keys[0], key: keys[1], value: elements.model })
                        } else {
                            store.dispatch('changeOptionsModal', { modal: { type: 'to', val: elements.page }, type: keys[0], value: elements.model })
                        }
                        break;
                }
            })


        }

        //permet de selectionner le composant field adéquate (sert dans arrayTable.vue, RoutingField, et calendarModule)
        Vue.prototype.getFieldComponent = (format) => {

            let component = textfield
            switch (format) {
                case 'textfield':
                case 'number':
                    component = textfield
                    break;
                case 'select':
                    component = select
                    break;
                case 'radio':
                    component = radio
                    break;
                case 'switch':
                    component = SwitchC
                    break;
                case 'colorpicker':
                    component = colorpicker
                    break;
                case 'arraytable':
                    component = arraytable
                    break;
                case 'autocomplete':
                    component = autocomplete
                    break;
                case 'datepicker':
                    component = datepicker
                    break;
                case 'datetimepicker':
                    component = datetimepicker
                    break;
                case 'textarea':
                    component = textarea
                    break;
                case 'fileinput':
                    component = fileinput
                    break;
                case 'texteditor':
                    component = texteditor
                    break;
                case 'encartphoto':
                    component = encartphoto
                    break;
                default:
                    component = textfield
                    break;
            }

            return component;
        }
    },
};

export default functions;