<template>
  <div>

    <not-allowed v-if="!AllowedDisplay" :page="page" :module="idmodule" :data="datas"></not-allowed>
    <div v-if="AllowedDisplay">
    <v-scale-transition>

      <div v-show="init">      

        <importFile :page="page" :modal="modal" :form="form" :table="table" v-if="initImport" @close="()=>{initImport=false; readDatas();}"/>

          <!-- Dialog qui permet d'afficher les suivi d'emails-->

          <v-dialog
        v-model="dialogTraked"
        :width="($vuetify.breakpoint.xs) ? '100%' : '50%'">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    "{{ displayTracked.objet }}" à {{ displayTracked.to }}
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogTraked = false"><v-icon>mdi-close-thick</v-icon></v-btn>      
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                        v-if="trackedList.length"
                    :headers="[{text:'Status',value:'status'},{text:'Date',value:'date'}]"
                    :items="trackedList"
                    hide-default-footer>
                    </v-data-table>
                    <div v-else class="text-center">
                        En attente de réception
                    </div>

                        <div class="pa-4 ma-4 elevation-2 "  v-html="displayTracked.corp"></div>
                    </v-card-text>

            </v-card>
        </v-dialog>

          <!-- FIN Email-->



        <!-- Bouton d'ajout sur la table -->
        <v-row no-gutters justify="center" align="center">
          <v-col class="col-6 text-left pa-0 ma-0">
            <v-btn v-if="add" class="ma-1" @click="addLine">
              <span v-if="!add.icon">{{ add.text }}</span>
              <v-icon v-if="add.icon">{{ add.icon }}</v-icon>
            </v-btn>
          </v-col>
          <v-col class="col-6 text-left d-flex text-caption pa-0 ma-0">
            <span v-if="!hidepagination">page {{ axiosParams.params.pagination.page }}/{{ pageLength }} : </span> {{ totalrows }} ligne(s)
          </v-col>
        </v-row>

        <!-- actions sur choix multiple-->
        <div v-if="selectedItem.length && this.datas" class="text-center">
          <div v-for="(men, index) in datas[0].action" :key="index">
          <v-btn v-if="men.params.head" @click="onClickButton(selectedItem, men, $event,true)">
            <v-icon>{{ men.icon }}</v-icon> {{ men.name }}
          </v-btn>
          </div>
        </div>

        <div v-if="ifnofiltertext" class="text-center text-h5 blue lighten-5 pa-3 rounded">{{ ifnofiltertext }}</div>
        <!-- debut su tableau -->
        <v-data-table 
        v-model="selectedItem"
        :headers="headers" 
        :items="datas" 
        :loading="loading" 
        hide-default-footer 
        :disable-pagination="hidepagination"
        :show-select="axiosParams.params.pagination.showselect"
        @input="selectAll"
        show-expand
        fixed-header
        :height="tableHeight"
        :expanded.sync="expanded"
        :single-expand="true"
        item-key="id"
        :single-select="!axiosParams.params.pagination.singleselect"
        :options="axiosParams.params.pagination" 
        class="transparent auto-height-table" click:row=""
        :page="axiosParams.params.pagination.page" 
        @update:options="reloadTable" 
        :footer-props="{
  'items-per-page-options': rowsPerPageItems
}">

          <!-- Affichage des sommes -->
          <template slot="body.append">
            <td v-if="axiosParams.params.pagination.showselect" >
            </td> 
            <td class="grey-lighten-3" v-for="head in headers" :key="head.cle">

                <div v-if="head.value == 'action' && somme.length" class="text-center">Somme</div>
                <div :class="'text-' + head.align">{{ somme[head.value] }} </div>

                <div v-if="head.value == 'action' && average.length" class="text-center">Moyenne</div>
                <div :class="'text-' + head.align">{{ average[head.value] }} </div> 

            </td>
          </template>

        <!-- Affichage des action en header pour import ou export -->
          <template v-slot:[`header.action`]="{ item }">
            {{ item }}
            <v-menu offset-y >
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-chevron-down</v-icon>
              </template>
              <v-list>

                  <v-list-item @click="exportExcel" >
                  <v-list-item-title><v-icon>mdi-file-excel-outline</v-icon> exporter le tableau</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="initImport = true" >
                  <v-list-item-title><v-icon>mdi-download</v-icon> Importer à partir d'un fichier</v-list-item-title>
                  </v-list-item>

              </v-list>
            </v-menu>
          </template>

          <!-- Affichage ligne par ligne -->
  <template v-slot:item="{ item,expand,isExpanded }" >

    <tr @click="expand(!isExpanded)" :class="['v-data-table--selected',{'v-data-table__mobile-table-row':$vuetify.breakpoint.xs}]">
      <td v-if="axiosParams.params.pagination.showselect">
        <v-checkbox v-model="item.selected" class="ma-0" justify="center" align="center"
        @click="toggleSelected(item)"
        red></v-checkbox>

      </td>   
    <td v-for="(head,i) in headers" :key="i" 
    v-bind:style="[item['cellBackColor']!='' ? {'background-color' : item['cellBackColor']} : {'background-color' :'none'}]"
    :class="{'v-data-table__mobile-row':$vuetify.breakpoint.xs}">
      
    <!--on place ce div qui est affihé uniquement si version mobile (affiche le nom de la colonne à gauche du résultat)-->
    <div class="v-data-table__mobile-row__header" v-html="head.text" v-if="$vuetify.breakpoint.xs"></div>

    <div v-if="head.value!='action'" v-html="item[head.value]" 
    @click="showTracked(item)"
      :class="['text-'+head.align,{'v-data-table__mobile-row__cell':$vuetify.breakpoint.xs}]"
      :style="'background-color:'+item['rowBackColor']+'; color:'+item['rowColor']+';'"
      ></div>

      <div v-if="head.value=='action' && item.action.length" 
      :class="['text-center',{'v-data-table__mobile-row__cell':$vuetify.breakpoint.xs}]">
            <v-menu offset-y >
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-chevron-down</v-icon>
              </template>
              <v-list>
                <template v-for="(men, index) in item.action" >
                  <v-list-item v-if="!men.params.head" :key="index" @click="onClickButton(item, men, $event)" >
                  <v-list-item-title><v-icon class="mr-4">{{ men.icon }}</v-icon>{{ men.name }}</v-list-item-title>
                </v-list-item>
                </template>

              </v-list>
            </v-menu>      
      </div>
      </td>

    </tr> 
 
  </template>

          <!-- fin ligne par ligne -->


          <!-- Encart d'expension juste en dessous de la ligne ca pourrait servir-->
      <template v-slot:expanded-item="{ }">
        <!-- <td :colspan="headers.length">Expanded Content</td> -->
        
      </template>

          <!-- Affichage du footer : pagination et nbr par page-->
          <template slot="footer">
            <v-row justify="center" no-gutters>

              <v-col lg="4" class="text-center d-flex align-center justify-center text-caption" v-if="!hidepagination">
                <v-pagination v-model="axiosParams.params.pagination.page" :length="pageLength" :total-visible="7"
                  @input="onPageChange"></v-pagination>


              </v-col>
              <v-col class="text-center d-flex align-center justify-center text-caption">
                <span v-if="!hidepagination">page {{ axiosParams.params.pagination.page }}/{{ pageLength }} : </span> {{ totalrows }} ligne(s)
              </v-col>
              <v-col lg="1" class="text-center d-flex align-center justify-center text-caption" v-if="!hidepagination">
                <v-select class="text-center" v-model="axiosParams.params.pagination.itemsPerPage"
                  :items="rowsPerPageItems" :full-width="false" v-on:change="onPerPageChange"></v-select>
              </v-col>
              <v-col lg="2" class="text-center d-flex align-center justify-start text-caption" v-if="!hidepagination">
                lignes/page
              </v-col>
            </v-row>

          </template>
          <!-- fin footer -->


          <!-- quand il n'y a aucune ligne -->
          <template v-slot:no-data>
            <v-alert :value="true">
              Aucune information disponible sur votre recherche
            </v-alert>
          </template>
        </v-data-table>
      </div>

    </v-scale-transition>
  </div>
  </div>
</template>

<script>
import trackemail from './trackedEmail'
export default {
// form étant le "ref='form'" de la page, ca sert à valider le formulaire d'ici
  props: ['idmodule', 'page', 'modal','form','hook'],
  mixins : [trackemail],
  components : {
                importFile   : () => import('./importFile.vue'),
        },

  data: function () {
    return {

      expanded: [],
      // permet de ne pas effectuer de reloadTable lors de la première initiation (voir fonction reloadTable)
      init: false,
      //
      initImport : false,
      //serv pour l'imortation de fichier
      table:'',
      headers: [],
      datas: [],
      // liste les lignes sélectionnés :
      selectedItem : [],
      // permet de stopper l'enregistrement dans un form si aucune ligne est présente dans ce tableau
      stopWhenNoLine : false,
      // permet de voir si on affiche le bouton ajout ou psa :
      add: {},
      // permet d'affiche le chargement sur le tableau
      loading: false,
      totalrows: 0,
      pageLength: 1,
      // affiche le total par colonne si il y a 
      somme: [],
      // affiche la moyenne par colonne si il y a
      average: [],
      // voir la fonction onPerPageChange
      perperChange: false,
      // recupère les modules de recherche lié à ce tableau
      searchmodule: [],
      // permet de définir si ce module est intégré dans une page form (nouvelle:newform ou en mode édition:editform), ou une page classique : page
      typePage : (!this.$store.getters['listForm'](this.page)) ? 'page' : this.$store.getters['listForm'](this.page).typeform ,

      rowsPerPageItems: [5, 10, 20, 50, 100],

      // va stocker l'url et les params de la page, essentiellement pour enregistrer le formulaire

      axiosParams: {type: 'post', url: 'displaymodule', params: { id: this.idmodule, pagination: {page:1}, querys: {}, params: {} } },

      //permet d'afficher un texte personnalisé si aucun filtre n'est appliqué sur la table
      ifnofiltertext : '',

      hidepagination : false,
      AllowedDisplay: true,
      // pour fixer le header avec un height auto :
      rowHeight: 55, // Hauteur approximative d'une ligne de la table
      tableHeight: 'auto', // Valeur initiale
      // fonction alimenté par les boutons d'actions, permet d'effectuer un callback apres le click. appelé dans le watch :callBackOnCloseModal (pour l'instant)
      callback: function () { return false },
    }
  },
  computed: {
    numberOfRows() {
      return this.datas.length;
    },

    callBackOnCloseModal() {
      return this.$store.getters.modalShowParams({ type: 'from', val: this.page })
    },


    //reloadTables est alimenté par un retour de ventilation, activé par vuex (voir sur cette page : callBackOnCloseModal)
    isReload() {
      return this.$store.getters.reloadTables
    },

    //si on est dans une modal, si la query change, on reload les tableau qui sont liés à la recherche
    isNewFilterOnModal() {
      return (this.$store.getters.modalShowParams({ type: 'to', val: this.page })) ? this.$store.getters.modalShowParams({ type: 'to', val: this.page }).query : false;
    }

  },
  watch: {

    isNewFilterOnModal(val) {

        for(var key in val) {
          let findModSearch = key.split(/_(.*)/s);

          this.searchmodule.forEach( (idsearch) => {
            if (findModSearch[0] == idsearch) this.axiosParams.params.querys[key] = val[key]
          })
          this.readDatas();

        }

    },


    numberOfRows: {
      immediate: true,
      handler() {
        this.adjustTableHeight();
      }
    },

    isReload(val) {
      
      if (val.includes(this.idmodule)) {
        
        var modal = this.$store.getters.modalShowParams({ type: 'to', val: this.page })
        this.axiosParams.params.params = (this.modal && modal.show) ? modal.params : this.$route.params
        this.readDatas();
        this.$store.dispatch('removeReloadTables', this.idmodule);
      }
    },

    callBackOnCloseModal: {
      // permet de rafraichir quand la modal de modif du module de la page se ferme
      handler(val) {

        //on met this.callback()!==false car il peut ya voir d'autres tableaux sur la page, et les callback se lancent aussi..
        //qaund la modal se ferme
        if (val.show === false && val.fromModule == this.idmodule) {
          // rafraichissement automatique des ligens de la table
          this.$store.dispatch('addReloadTables', { modules: this.idmodule })
          // on met un if mais il se lance direct dans le if
          if (this.callback() !== false) {
            // on remet la function callback vide:
            this.callback = function () { }
          }
        }
      }, deep: true


    },



  },
  methods: {

    adjustTableHeight() {
      const maxVisibleRows = 10; // Limite maximale de lignes visibles
      const headerHeight = 56; // Hauteur de l'en-tête du tableau

      const rowsToDisplay = Math.min(this.numberOfRows, maxVisibleRows);
      this.tableHeight = headerHeight + rowsToDisplay * this.rowHeight + 'px';
    },

    exportExcel() {
      this.axiosParams.params.export='all';
      this.axiosParams.apidefault = {responseType:'blob',headers:{'Accept':'application/vnd.ms-excel'}}
      this.API(this.axiosParams).then((resp) => {
        this.axiosParams.apidefault = {responseType:'',headers:{'Accept':'application/json'}}
        const url = window.URL.createObjectURL(new Blob([resp.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'excel.xlsx');
                        document.body.appendChild(link);
                        link.click();        
      })
    },

    toggleSelected(item) {

      if(!item.selected) this.selectedItem = this.selectedItem.filter(el => el.id !=item.id)
      if(item.selected) this.selectedItem.push(item)
    },

    selectAll(all) {
      this.datas.forEach((item)=> {
          const foundIndex = all.findIndex(i => i.id === item.id);
          item.selected = (foundIndex>=0)? true : false;
        }) 
    },

    // permet de construire l'array du header
    parseHeader: function (header) {
      var head = []
      header.forEach((val) => {
        var text = (val.affichage == '_act') ? "" : val.affichage;
        var isAct = (val.affichage == '_act') ? true : false;
        var setHead = { text: text, value: val.cle, align: val.display.align, sortable: val.display.issortable,isAct:isAct }

        head.push(setHead);
      });

      // permet d'afficher l'expansion en dessousde chaque champ (c'est actif par défaut, mais il n'y a rien dans le contenu pour l'instant)
      head.push({ text: '', value: 'data-table-expand' })

      return head;
    },

    readDatas() {

      this.loading = true;
      this.API(this.axiosParams).then((resp) => {
        const datas = resp.data
        if (!resp.data.success) {
          this.AllowedDisplay = false;
          this.datas = resp.data
          this.$emit('isloaded', { mod: this.idmodule, 'resp': true });
          return false;
        }

        this.headers = this.parseHeader(datas.headertab);
        this.datas = datas.datas
        // on applique si des lignes sont selectionnés ou pas :
        this.datas.forEach((item)=> {
          const foundIndex = this.selectedItem.findIndex(i => i.id === item.id);
          if(foundIndex>=0) item.selected = true
        }) 
        this.stopWhenNoLine = true
        
        this.pageLength = datas.totalPage
        this.totalrows = datas.totalrows
        this.loading = false;
        this.add = datas.add
        this.somme = datas.somme;
        this.average = datas.average;
        this.searchmodule = datas.search;
        this.hidepagination = datas.pagination.hidepagination
        this.table = datas.table
        this.ifnofiltertext = datas.ifnofiltertext

              this.axiosParams.params.page = datas.pagination.page;
              this.axiosParams.params.pagination = datas.pagination;
              this.axiosParams.params.pagination.itemsPerPage = datas.pagination.itemsPerPage;                     

        setTimeout(() => {
          this.init = true;
        }, 100)

      })

    },

    onPageChange: function (nb) {
      // variable page obligatoire afin que laravel puisse change de page dans le controller. serv uniquement à ca, il faut le post ou le get page=x
      this.axiosParams.params.page = nb
      this.readDatas();
    },

    onPerPageChange: function () {
      // on remet la page à 1 sinon selon le nombre de lignes, si on fait un affichage à 100 avec la page 5, on verra rien 
      this.perperChange = true
      // on ne met pas le this.getFromAPI() ici car en faisant un change, le v-model est = dataSend.pagination.itemsPerPage
      //donc ca fait un reload automatique par le biais du @update:options="reloadTable" de v-data-table
    },

    reloadTable: function (options) {

      // this.init permet de ne pas charger la table lors du premier load car :
      // lors de l'ouverture du component, les options de pagniation de v-data-table sont à {}.
      //le fait est que v-data-table attribue par défaut des paramétrages d'options basiques mais qui ne sont pas les notre dans le module.
      // donc en ouvrant le composant ca fait un reload direct ce qui entraine un this.getFromAPI() avec les mauvaises infos.

      if (this.init) {
        
        this.axiosParams.params.pagination = {...this.axiosParams.params.pagination, ...options}

        if (this.perperChange) {

          this.axiosParams.params.pagination.page = 1
          this.axiosParams.params.page = 1
          this.perperChange = false;
        }

        this.readDatas();
      }
    },

    onClickButton: function (item, men, event,fromSelected) {
      this.clickActions({args:item,action:men,idmodule:this.idmodule,page:this.page,selectedLines:fromSelected,
      success:(datas) => {
        if(datas.message) this.$toast.success(datas.message);
          this.readDatas();
      }
      })

    },

    addLine() {

      let getParentTable = this.$store.getters.specificPage(this.page).table

          // on créer les variables provenants du module partie ajout :
          let variables = {};
          this.add.variables.forEach((v) => {
            variables[v.key] = this.replaceBy({ text: v.value, page: this.page });
          })
      // si la page d'édition de la table ce module est créé
        if(this.add.href) {
          variables['id'] = 'new';
        // si ce module est intégré dans une page de type form et que ce form on enregistre avant d'ajouter une ligne dans la table de ce module
        if (this.typePage=='new' || this.typePage=='edit') {
          
          
          
          // on enregistre le formulaire avant l'envoi sur la page d'édition de ce module' :
          this.validateForm({form:this.form,page:this.page,validate:1,
            ifNotValid:()=>{
              this.$toast.warning('Vérifiez certains champs non complétés',{position: "top-center",timeout: 3000});
            },
            callback:(datas)=> {
              variables['idparent'] = datas.args.id;
              this.goTo({ rootPage: this.page, page: this.add.href, type: this.add.type, replace: variables, paramDialog: this.add.paramdialog, fromModule: this.idmodule })
            }
          }) 

        } else {

          // si ce module est dans une page, on envoi la page d'édition de la table de ce module
          this.goTo({ rootPage: this.page, page: this.add.href, type: this.add.type, replace: variables, paramDialog: this.add.paramdialog, fromModule: this.idmodule })          
        }
        
      
      } else {
        // si la page d'édition de la table ce module n'est pas créé
        if (confirm("la page d'édition de la table " + this.add.table + " n'est pas créée, voulez-vous la créer maintenant ? ")) {

          variables['id'] = 'new';
          // si ce module est intégré dans une page de type form et que ce form on enregistre avant d'ajouter une ligne dans la table de ce module
          if (this.typePage=='new' || this.typePage=='edit') {
            
            // on enregistre le formulaire avant l'envoi sur la page d'édition de ce module' :
            // on met validate 1 pour signifier que c'est un enregistrement classique
            this.validateForm({form:this.form,page:this.page,validate:1,
              ifNotValid:()=>{
                this.$toast.warning('Vérifiez certains champs non complétés',{position: "top-center",timeout: 3000});
              },
              callback:(datas)=> {
                        // après l'enregistrement on créer la nouvelle page form:
                        this.createNewPage({ name: 'edit' + this.add.table, type: 'form', table: this.add.table,parentform:getParentTable,url:'/interface/'+getParentTable+'/:idparent/'+this.add.table+'/:id/edit' ,
                                callback:()=> {
                                  variables['idparent'] = datas.args.id;
                                this.goTo({ rootPage: this.page, page: 'new'+this.add.table, type: this.add.type, replace: variables, paramDialog: this.add.paramdialog, fromModule: this.idmodule })
                                }
                            }); 
              }
            }) 

          } else {
            // si ce module est dans une page, on envoi la page d'édition de la table de ce module
            this.createNewPage({ name: 'edit' + this.add.table, type: 'form', table: this.add.table,parentform:getParentTable,url:'/interface/'+this.add.table+'/:id/edit' ,
                                callback:()=> {
                                this.goTo({ rootPage: this.page, page: 'new'+this.add.table, type: this.add.type, replace: variables, paramDialog: this.add.paramdialog, fromModule: this.idmodule })
                                }
                            });         
          }

        }

      }

    }



  },
  // created s'effectue avant le mounted
  created() {

  },

  mounted() {
    // on prend les filtres de recherches pour l'utiliser dans le watch
    this.searchData = this.$store.getters['getSearchValues'];
    // permet de récupérer les id qui proviennent de l'url
    var modal = this.$store.getters.modalShowParams({ type: 'to', val: this.page })
    this.axiosParams.params.params = (this.modal && modal.show) ? modal.params : this.$route.params

    this.axiosParams.params.querys = (this.modal && modal.show) ? modal.query : JSON.parse(JSON.stringify(this.$route.query));

    if(this.hook == 'search') this.axiosParams.params.querys.global = this.$store.getters.getStringSearch;

    this.readDatas();

  }

}

</script>

<style scoped>
.auto-height-table {
  /* Ajout d'une règle de base pour l'auto-ajustement */
  overflow-y: auto;
}

</style>
